/* TODO: cleanup  */

$loader-color: #0f1d32;
$app-bg-color: #F8F8F8;

$nav-button-bg: #143353;

$darkest-bg: #0f1d32;


$main-button-bg: #4283eb ;
$main-button-bg-hover: #1866e2;
$main-button-border-active: #a3c8ff;

@import "loader";

body {
    
    background-color: #eee;
}
#root {
    background-color: $app-bg-color;
}
* {
    font-size:16px;
    font-family: 'Open Sans', sans-serif;
    font-weight: 400;
}

  
h1,h2,h3,h4 {
    
    font-weight: 600;
    a.link {
        margin-left: 6px;
        margin-right: 6px;
        font-weight: 600;
    }
}
h1 {
    font-size: 20px;
}

.text-danger {
    color: red;
    margin-top: 4px;
}


.logo-bw {
    background-image: url('./images/logo-bw.svg');
    background-position: center center;
    background-repeat: no-repeat;
    min-width: 136px;
    min-height: 30;
    line-height: 30px;
    overflow: hidden;
    text-indent: -500px;
}

.top-nav {
    overflow: hidden;
    padding: 16px;
    background-color: $darkest-bg;

    .logo-bw {
        display: block;
        float: left;
        text-decoration: none;
        padding:5px 15px;
    }
    .signout {
        position: relative;
        display: block;
        float: right;
        text-decoration: none;

        &::before {
            content: '';
            right: 12px;
            position: absolute;
            display: block;
            width: 30px;
            height: 30px;
            background-image: url('./images/signout.svg');
            background-position: center;
            background-repeat: no-repeat;
            background-size: contain;
            
        }
        transition-duration: .3s;
        padding:5px 15px;
        padding-right: 55px;
        line-height: 30px;
        overflow: hidden;
        color: #fff;
        border-top-left-radius: 30px;
        border-bottom-left-radius: 30px;
        border-top-right-radius: 30px;
        border-bottom-right-radius: 30px;
        &:hover {

            background-color: $nav-button-bg;
        }
    }
    
}

.a-button {
    position: relative;
    text-transform: uppercase;
    font-weight: 600;
    font-size: .9em;
    min-width: 200px;
        border: 2px solid transparent;
        transition-duration: .3s;
        display: inline-block;
        text-decoration: none;
        &:active {
            border-color: $main-button-border-active;
        };

        padding:5px 15px;
          line-height: 30px;
        overflow: hidden;
        
        border-top-left-radius: 30px;
        border-bottom-left-radius: 30px;
        border-top-right-radius: 30px;
        border-bottom-right-radius: 30px;

        color: #fff;
        background-color: $main-button-bg;
        &:hover {
            background-color: $main-button-bg-hover;

        }
    
}
.a-button.secondary {
    position: relative;
    text-transform: uppercase;
    font-weight: 600;
    font-size: .9em;
        border: 2px solid transparent;
        transition-duration: .3s;
        display: inline-block;
        text-decoration: none;
        &:active {
            border-color: $main-button-border-active;
        };
       
        padding:5px 15px;
        
        line-height: 30px;
        overflow: hidden;
        
        border-top-left-radius: 30px;
        border-bottom-left-radius: 30px;
        border-top-right-radius: 30px;
        border-bottom-right-radius: 30px;

        color: #000;
        background-color: transparent;

    
}
.a-button.go {
    &::before {
        content: '';
        right: 12px;
        position: absolute;
        display: block;
        width: 30px;
        height: 30px;
        background-image: url('./images/arrow--white.svg');
        background-position: right center;
        background-repeat: no-repeat;
        background-size: 100%;
        transition-duration: .3s;
    }
    &:hover {
        background-color: $main-button-bg-hover;

    
        &::before {
            right: 4px;
        }
    }
    //padding-right: 45px;
    }

.a-button.cancel {
    margin-right: 15px;
    @media only screen and (max-width: 600px) {
    margin-bottom: 10px;
    margin-right: 0px;
    }

    background-color: #eeecec;
&::before {
    content: '';
    left: 37px;
    position: absolute;
    display: block;
    width: 30px;
    height: 30px;
    background-image: url('./images/arrow--black.svg');
    background-position: right center;
    background-repeat: no-repeat;
    background-size: 100%;
    transition-duration: .3s;
    transform: rotate(180deg);
}
&:hover {
    background-color: #ddd;
    &::before {
        left: 14px;
    }
}
//padding-left: 45px;
}


.a-button.save {
    &::before {
        content: '';
        right: 16px;
        position: absolute;
        display: block;
        width: 30px;
        height: 30px;
        background-image: url('./images/checkmark.svg');
        background-position: right center;
        background-repeat: no-repeat;
        background-size: 60%;
        transition-duration: .3s;
    }
    &:hover {
        background-color: $main-button-bg-hover;
    }
    &.errors {
        border-color: red;
    }
}


.profile-block-wrap {
    overflow: hidden;
}
.profile-block {
    max-width: 800px;
    margin: auto;
    
    margin-bottom:30px;


    .header {
        margin-left: 10px;
        color:$darkest-bg;
        overflow: hidden;
       font-size: 30px;
        font-weight: 600;
        text-align: left;
        .debug {
            display: block;
            font-size:14px; color:silver;
        }
        @media only screen and (max-width: 600px) {
            font-size: 24px;
            margin-left: 15px;
        }
    };
    .content {
        border-radius: 5px;
        overflow: hidden;
        background-color: #fff;
        padding: 20px 10px;
        margin-top:15px;
        box-shadow: 0 0 20px #00000014;

        &.info-only {
            background: transparent;
            box-shadow: none;
            margin-top:0;
            padding-bottom:0;
        }

        .data-row {
            width: 100%;
            display: block;
            overflow: hidden;
            padding: 8px 0;
            .data-label  {
                text-align: left;
                display: block;
                overflow: hidden;
                float: left;
                
                width: 40%;
                
                label {
                    span {
                        font-weight: 600;
                    }
                    display: block;
                    color:#000;
                    font-weight: 600;
                    padding-left: 15px;
                    
                }
            }
            .data-prop  {
                text-align: left;
                color:#666;
                display: block;
            overflow: hidden;
            float: right;
                
                width: 60%;
            }
            @media only screen and (max-width: 600px) {
                .data-prop {
                    padding-top: 3px;
                    padding-left: 15px;
                    width: 100%;
                    float: none;
                }
                .data-label {
                    float: none;
                    width: 100%;
                    label {
                    font-size: 12px;
                    }
                    margin-bottom: 5px;;
                } 
            }

        }
    }
    .actions-row {
        overflow: hidden;
        padding: 20px 0;
        padding-top:25px;
        text-align: center;
    }
}

.data-prop input {
    outline: 0;
    padding: 0;
    padding-bottom:10px;
    width: 90%;
    box-sizing: border-box;
    border: 0;
}


.data-prop input + div.activity {
    position: relative;
    overflow: hidden;
    height: 1px;
   margin-bottom: 1px;
    background-color: #dedede;
    width: 92%;
}

.data-prop input:focus + div.activity {
    position: relative;
    overflow: hidden;
    height: 2px;
    margin-bottom: 0;
    background-color: $main-button-bg;

}
a.link {
    color:#1866e2;
    display: inline;
}
strong {
    font-weight: bold;
    
}
.iban-warning {
    color:#e16c04;
}
.iban-ok {
    color: #097500;
}
.html-option {
    cursor: pointer;
    min-height: 40px;

    padding-left: 40px;
    background-image: url(images/option.svg);
    background-position: left top;
    background-size: 28px;
    background-repeat: no-repeat;

    &.is-selected {
        background-image: url(images/option-selected.svg);
    }

    &.is-disabled {
        cursor: default;
        background-image: url(images/option-disabled.svg);
        color: #b3b3b3;
        text-decoration: line-through;
        a.link {
            color: #b3b3b3;
            text-decoration: line-through;
        }
    }

}

.description {
    padding: 0;
    text-align: left;
    margin-left: 10px;
    margin-top: 5px;
    margin-bottom: 30px;
    color: gray;
    div {
        font-weight: bold;
    }
    @media only screen and (max-width: 600px) {
        margin-left: 15px;
    }
}

.about-app {
    .info {
        padding: 10px;
        padding-bottom: 15px;
        margin: auto;
        max-width: 400px;;
        color: gray;
        font-weight: bold;
    }
}

.account-used {
    //DISABLED, may be used for dev anyway
    //display: inline-block;
    //border-bottom: 2px solid #097500;
}

.info-box-center {
    overflow: hidden;
    max-width: 500px;
    margin: auto;
    margin-top: 40px;
    margin-bottom: 80px;
}


.info-box {

    display: table-row;
    .info-box-icon {
        display: table-cell   ;
        min-width:80px;
        vertical-align: middle;
        text-align: center;
    }
    .info-box-content {
        line-height: 1.3;
        text-align: left;
        padding: 20px;
        padding-left: 0px;
        display: table-cell   ;
        width: 100%;
        a {
            margin-left: 5px;
            margin-right: 5px;
        }
    }
}